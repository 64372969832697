
export default 
{ cardClipBoard: "model://perspectives.domains#System$PerspectivesSystem$External$CardClipBoard"
, currentLanguage: "model://perspectives.domains#System$PerspectivesSystem$External$CurrentLanguage"
, sysUser: "model://perspectives.domains#System$PerspectivesSystem$User"
, system: "model://perspectives.domains#System$PerspectivesSystem"
, systemExternal: "model://perspectives.domains#System$PerspectivesSystem$External"
, notifications: "model://perspectives.domains#System$ContextWithNotification$Notifications"
, allNotifications: "model://perspectives.domains#System$PerspectivesSystem$AllNotifications"
, notificationMessage: "model://perspectives.domains#System$ContextWithNotification$Notifications$Message"
, isOnScreen: "model://perspectives.domains#System$ContextWithScreenState$External$IsOnScreen"
, nrOfUploadedFiles: "model://perspectives.domains#SharedFileServices$SharedFileServices$DefaultFileServer$NrOfUploadedFiles"
, disabled: "model://perspectives.domains#SharedFileServices$SharedFileServices$DefaultFileServer$Disabled"
}