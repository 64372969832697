(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("perspectives-proxy"), require("react-bootstrap"), require("i18next"), require("@primer/octicons-react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "perspectives-proxy", "react-bootstrap", "i18next", "@primer/octicons-react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["perspectives-react"] = factory(require("react"), require("prop-types"), require("perspectives-proxy"), require("react-bootstrap"), require("i18next"), require("@primer/octicons-react"), require("react-dom"));
	else
		root["perspectives-react"] = factory(root["react"], root["prop-types"], root["perspectives-proxy"], root["react-bootstrap"], root["i18next"], root["@primer/octicons-react"], root["react-dom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_perspectives_proxy__, __WEBPACK_EXTERNAL_MODULE_react_bootstrap__, __WEBPACK_EXTERNAL_MODULE_i18next__, __WEBPACK_EXTERNAL_MODULE__primer_octicons_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__) => {
return 