// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for Perspectives React components */
.mediumDotted{
  border-width: medium!important;
  border-style: dotted!important;
}

/* These color codes are taken from bootstrap. See https://getbootstrap.com/docs/5.0/utilities/colors/#variables
for a discussion about variables and sass. */

.failure:focus{
  outline: none;
  box-shadow: 0 0 0 5px #dc3545; /* \$red */
}

.dropHere{
  outline: none;
  box-shadow: 0 0 0 5px #0dcaf0; /* \$cyan */
}

.card:focus, .dropzone:focus {
	outline-color: rgb(0, 95, 204);
	outline-style: auto;
	outline-width: 1px;
}

.navbarCard {
  padding-top: 4px!important;
  padding-bottom: 4px!important;
}

.widget {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;4CAC4C;;AAE5C;EACE,aAAa;EACb,6BAA6B,EAAE,SAAS;AAC1C;;AAEA;EACE,aAAa;EACb,6BAA6B,EAAE,UAAU;AAC3C;;AAEA;CACC,8BAA8B;CAC9B,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* Custom styles for Perspectives React components */\n.mediumDotted{\n  border-width: medium!important;\n  border-style: dotted!important;\n}\n\n/* These color codes are taken from bootstrap. See https://getbootstrap.com/docs/5.0/utilities/colors/#variables\nfor a discussion about variables and sass. */\n\n.failure:focus{\n  outline: none;\n  box-shadow: 0 0 0 5px #dc3545; /* $red */\n}\n\n.dropHere{\n  outline: none;\n  box-shadow: 0 0 0 5px #0dcaf0; /* $cyan */\n}\n\n.card:focus, .dropzone:focus {\n\toutline-color: rgb(0, 95, 204);\n\toutline-style: auto;\n\toutline-width: 1px;\n}\n\n.navbarCard {\n  padding-top: 4px!important;\n  padding-bottom: 4px!important;\n}\n\n.widget {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
